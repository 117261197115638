<template>
	<v-card class="d-flex flex-column my-card">
		<v-row no-gutters dense class="d-flex align-start flex-grow-0 pb-6">
			<v-col cols="12">
				<p class="heading-sf20">Transaction History</p>
			</v-col>
		</v-row>

		<div v-if="transactions.length === 0">
			<div class="d-flex align-center justify-center" style="height: 45vh">
				<p class="no-data">No notifications available</p>
			</div>
		</div>

		<transition-group class="transaction-body" v-if="transactions.length > 0" name="slide-in" :style="{ '--total': transactions.length }">
			<div v-for="(data, index) in transactions" v-bind:key="'notification' + index" :style="{ '--i': index }" class="d-flex align-center">
				<v-row no-gutters dense>
					<v-col cols="12" class="pa-0 transaction-data d-flex flex-sm-row flex-column align-sm-center">
						<div class="mt-sm-0 mt-2" style="width: 100px">
							<p class="heading-mf15-blue">{{ $options.filters.currency(data.payment_amount) }}</p>
						</div>
						<div class="ml-sm-6 d-sm-block d-none">
							<p class="heading-rf13">List ref: {{ data.list_id }}</p>
						</div>
						<div class="d-sm-flex d-none ml-sm-auto">
							<p class="heading-rf13">{{ dateFormat(data.created_at) }}</p>
						</div>
						<div class="d-sm-flex d-none ml-6">
							<p class="text-tag-btn text-tag-blue ma-0" v-if="data.status === 'P'">Pending</p>
							<p class="text-tag-btn text-tag-green ma-0" v-else>Done</p>
						</div>
						<div class="d-sm-none d-flex justify-space-between align-center">
							<div class="d-flex ml-sm-auto flex-column mt-1">
								<p class="heading-rf13">List ref: {{ data.list_id }}</p>
								<p class="heading-rf13">{{ dateFormat(data.created_at) }}</p>
							</div>
							<div class="d-flex ml-6">
								<p class="text-tag-btn text-tag-blue ma-0" v-if="data.status === 'P'">Pending</p>
								<p class="text-tag-btn text-tag-green ma-0" v-else>Done</p>
							</div>
						</div>
					</v-col>
				</v-row>
			</div>
		</transition-group>

		<v-row no-gutters dense align="stretch" class="d-flex">
			<v-col cols="12" class="d-flex flex-column justify-end mt-auto">
				<div class="footer-shadow"></div>
				<div class="total-display d-flex align-center">
					<p class="heading-sf15">Total:</p>
					<p class="heading-sf15 ml-auto">{{ $options.filters.currency(total) }}</p>
				</div>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
import { userProfileUpdate } from '../../services/authservices'
import { localDateFromDateTime } from '../../services/commonservice'
export default {
	data() {
		return {
			show: true,
		}
	},

	computed: {
		transactions() {
			return this.$store.state.setting.transaction_history
		},

		total() {
			var total = 0
			var transaction = this.$store.state.setting.transaction_history
			transaction.forEach((payment) => {
				total = total + payment.payment_amount
			})

			return total
		},
	},

	methods: {
		dateFormat(date) {
			return localDateFromDateTime(date)
		},
	},
}
</script>

<style lang="scss" scoped>
    .footer-shadow {
    	margin: 0px -32px 0px -32px;
    	margin-top: -80px;
    	min-height: 80px;
    	z-index: 9;
    	background-image: linear-gradient(180deg, rgba(239, 239, 239, 0) 0%, rgba(236, 239, 246, 0.72) 100%);
    }
    .total-display {
    	height: 70px;
    	width: 100%;
    	margin: 0px 0px -32px 0px;
    }
    .transaction-body {
    	height: inherit;
    	min-height: 400px;
    	z-index: 4;
    	overflow-y: scroll;
    	max-height: 480px;
    	scrollbar-width: 1px !important;
    }

    .transaction-body::-webkit-scrollbar {
    	width: 0px;
    }

    .transaction-data {
    	height: 70px;
    	border-bottom: 1px solid #e7eaf3;

    	.text-tag-btn {
    		width: 88px !important;
    		padding: 10px 8px;
    	}
    }

    @media only screen and (max-width: 600px) {
    	.transaction-data {
    		height: 80px;
    	}
    }
</style>